import React from 'react'
import moment from 'moment'
import { max } from 'lodash'
import DOMPurify from 'dompurify'
import {
  Pane,
  Card,
  Heading,
  Text,
  ArrowRightIcon,
  TickIcon,
  DotIcon,
  defaultTheme,
} from 'evergreen-ui'

import CardTitle from '../core/CardTitle'
import { getGlobalState } from '../../hooks/use-global-state'

const { colors } = defaultTheme

const LadderLevel = (ladderLevel) => {
  const { isMobile } = getGlobalState('deviceType')
  const user = getGlobalState('user')
  const { achievedAt } = user.ladderLevels.find((userLevel) => (
    userLevel.ladderLevelId === ladderLevel.id
  )) || {}

  const isNextLevel = max(
    user.ladderLevels.map((userLevel) => userLevel.levelNumber + 1),
  ) === ladderLevel.levelNumber

  const iconStyle = {
    minWidth: 40, size: 26, marginRight: 10, marginTop: 1, marginBottom: -1,
  }

  return (
    <Pane
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      flexShrink={isMobile ? 0 : 1}
      minWidth={isMobile ? '100%' : 'auto'}
      padding={10}>
      <Pane
        display="flex"
        alignItems="center">
        {isNextLevel
          ? <ArrowRightIcon {...iconStyle} color={colors.blue500} />
          : achievedAt
            ? <TickIcon {...iconStyle} color={colors.green300} />
            : <DotIcon {...iconStyle} color={colors.gray500} />}
        <Heading
          color={
            isNextLevel
              ? colors.blue500
              : achievedAt
                ? colors.green400
                : colors.gray700
          }>
          {ladderLevel.name}

          <Text
            marginLeft={5}
            fontSize={12}
            color={isNextLevel ? colors.blue400 : colors.gray600}>
            {achievedAt ? (
              `since at least ${moment(achievedAt).format('M/D/YYYY')}`
            ) : (
              'in progress'
            )}
          </Text>

        </Heading>
      </Pane>

      <Heading
        paddingLeft={50}
        size={300}
        color={isNextLevel ? colors.blue400 : colors.gray700}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ladderLevel.description) }} />

    </Pane>
  )
}

const ActivistLadder = (ladder) => {
  const { isMobile } = getGlobalState('deviceType')
  return (
    <Card
      display="flex"
      alignItems="center"
      flexDirection="column"
      elevation={2}
      margin={isMobile ? 8 : 20}
      width="100%"
      minWidth={!isMobile ? 560 : 200}
      height="auto"
      background="colors.gray50">
      <CardTitle title={ladder.name} />

      <Pane width="100%" padding={20} paddingTop={10}>
        {ladder.levels.map((ladderLevel) => (
          <LadderLevel key={ladderLevel.id} {...ladderLevel} />
        ))}
      </Pane>
    </Card>
  )
}

export default ActivistLadder
